













import {
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectMasterType extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: false, type: String, default: undefined })
  value!: string;

  @Prop({ required: false })
  placeholder!: string;

  @Prop({ required: true, type: String, default: "" })
  name!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchData(this.name);
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(name: string): void {
    this.loading = true;
    useFindMasterType(name)
      .then(res => {
        this.options = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
